// Handles api actions related to avatar frames
import axios from "../core/axios";
import { assertSuccess, paramsParser } from "../core";
import type {
  CollectionResult,
  CreateFrameSlotBody,
  EditFrameSlotBody,
  EmoteSlotEntity,
  FrameCreateBody,
  FrameEditBody,
  FrameEntity,
  FrameKind,
  FrameListParams,
  FrameSlotEntity,
  FrameSlotLadderEntity,
  FrameSlotListParams,
  IncludeArr,
  MessageResult,
  MyFrameListParams,
  Result,
  UserEntity,
} from "../types";
import { Headers } from "./generic";

export class Frame {
  static async get(params: FrameListParams, auth?: string) {
    const resp = await axios.get<CollectionResult<FrameEntity>>(
      "/profileframe" + paramsParser(params),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }

  static async getById(
    id: string,
    includes: IncludeArr<FrameEntity> = [],
    auth?: string,
  ) {
    const resp = await axios.get<Result<FrameEntity>>(
      `/profileframe/${id}` + paramsParser({ includes: includes }),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async create(body: FrameCreateBody, auth: string) {
    const resp = await axios.post<Result<FrameEntity>>(
      "/profileframe/create",
      body,
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async uploadImage(
    id: string,
    version: number,
    kind: FrameKind,
    file: File | Blob,
    auth: string,
  ) {
    const formData = new FormData();
    formData.append("file", file);

    const resp = await axios.post<Result<FrameEntity>>(
      `/profileframe/${id}/${kind}?version=${version}`,
      formData,
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async edit(id: string, body: FrameEditBody, auth: string) {
    const resp = await axios.post<Result<FrameEntity>>(
      `/profileframe/${id}`,
      body,
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async delete(id: string, version: number, auth: string) {
    const resp = await axios.delete<MessageResult>(
      `/profileframe/${id}?version=${version}`,
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async getCurrentUserFrames(params: MyFrameListParams, auth: string) {
    const resp = await axios.get<CollectionResult<FrameEntity>>(
      "/user/me/profileframe" + paramsParser(params),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }

  // this returns empty attributes
  static async setCurrentUserFrame(
    frameId: string,
    kind: FrameKind,
    version: number,
    auth: string,
  ) {
    const res = await axios.post<Result<UserEntity>>(
      `/user/me/profileframe/${kind}`,
      {
        profileFrameId: frameId,
        version,
      },
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(res.data).data;
  }

  static async unsetCurrentUserFrame(
    version: number,
    kind: FrameKind,
    auth: string,
  ) {
    const res = await axios.delete<Result<UserEntity>>(
      `/user/me/profileframe/${kind}`,
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
        data: {
          version,
        },
      },
    );

    return assertSuccess(res.data).data;
  }
}

export class FrameSlot {
  static async getById(
    id: string,
    includes: IncludeArr<EmoteSlotEntity>,
    auth: string,
  ) {
    const resp = await axios.get<Result<EmoteSlotEntity>>(
      `/emote/slot/${id}${paramsParser({ includes })}`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async get(params: FrameSlotListParams, auth: string) {
    const resp = await axios.get<
      CollectionResult<
        FrameSlotEntity,
        {
          extra: {
            totalSlotCount: {
              emoji: number;
              sticker: number;
            };
          };
        }
      >
    >("/profileframe/slot" + paramsParser(params), {
      headers: Headers.Bearer(auth),
    });
    return assertSuccess(resp.data);
  }

  static async create(body: CreateFrameSlotBody, auth: string) {
    const resp = await axios.post<Result<FrameSlotEntity>>(
      "/profileframe/slot/create",
      body,
      {
        headers: Headers.Bearer(auth),
      },
    );
    return assertSuccess(resp.data).data;
  }

  static async edit(slotId: string, body: EditFrameSlotBody, auth: string) {
    const resp = await axios.post<Result<FrameSlotEntity>>(
      `/profileframe/slot/${slotId}`,
      body,
      {
        headers: Headers.Bearer(auth),
      },
    );
    return assertSuccess(resp.data).data;
  }

  static async delete(slotId: string, auth: string) {
    const resp = await axios.delete<MessageResult>(
      `/profileframe/slot/${slotId}`,
      {
        headers: Headers.Bearer(auth),
      },
    );
    return assertSuccess(resp.data).data;
  }

  // TODO: impl
  static async getLadder() {
    return {
      type: "entity",
      data: {
        id: "profile_frame_slot_ladder",
        type: "profile_frame_slot_ladder",
        attributes: {
          baseAvatarSlotCount: 3,
          baseBannerSlotCount: 3,
          slotsByMilestone: [],
        },
        relationships: [],
      },
    }.data as FrameSlotLadderEntity;

    // const resp = await axios.get<Result<EmoteSlotLadderEntity>>(
    //   `/emote/slot/ladder`
    // );
    // return assertSuccess(resp.data).data;
  }
}
